import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './Announcement.css'; 

const Announcement = () => {

  const [announcements, setAnnouncements] = useState([]);
  const [events, setEvents] = useState([]);
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    const token = localStorage.getItem('token');
    axios.get('/api/announcement/', {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
      .then(response => {
        setAnnouncements(response.data);
      })
      .catch(error => console.error('Error fetching announcements:', error));
    axios.get('/api/event/', {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
      .then(response => {
        setEvents(response.data);
      })
      .catch(error => console.error('Error fetching events:', error));
  }, []);

  const onDateChange = (newDate) => {
    setDate(newDate);
    // Optionally, filter events based on selected date
  };

  return (
    <div className="announcement-container">
      <section id="anuncios">
        <h2>Anuncios</h2>
        <ul className="announcement-list">
          {announcements.map((announcement) => (
            <li key={announcement.id} className="announcement-item">
              <strong>{new Date(announcement.added_on).toLocaleDateString()}</strong>: {announcement.message}
            </li>
          ))}
        </ul>
      </section>
      <section id="Eventos">
        <h2>Eventos</h2>
        <Calendar 
          onChange={onDateChange}
          value={date}
        />
        <ul className="event-list">
          {events.map((event) => (
            <li key={event.id} className="event-item">
              <strong>{event.title}</strong><br />
              <em>{new Date(event.date_time).toLocaleString()}</em><br />
              {event.location}
            </li>
          ))}
        </ul>
      </section>
    </div>  
  );
}

export default Announcement;
