import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Register = () => {
    const [formData, setFormData] = useState({
        username: '',
        password: '',
        email: '',
    });
    const navigate = useNavigate();
    const [errors, setErrors] = useState(null);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post('/api/register/', formData)
            .then(response => {
                alert('Registro exitoso. Espere la aprobación.');
                navigate('/login'); // Redirect to the login page after successful registration
            })
            .catch(error => {
                console.error('Error durante el registro:', error);
                if (error.response && error.response.data) {
                    setErrors(error.response.data);
                }
            });
    };

    return (
        <div>
            <section id="register">
                <h2>Register</h2>
                <form onSubmit={handleSubmit}>
                    <label htmlFor="username">Nombre de Usuario:</label>
                    <input type="text" id="username" name="username" onChange={handleChange} required />
                    {errors && errors.username && <p>{errors.username}</p>}
                    
                    <label htmlFor="email">Correo Electrónico:</label>
                    <input type="email" id="email" name="email" onChange={handleChange} required />
                    {errors && errors.email && <p>{errors.email}</p>}

                    <label htmlFor="password">Contraseña:</label>
                    <input type="password" id="password" name="password" onChange={handleChange} required />
                    {errors && errors.password && <p>{errors.password}</p>}

                    <button type="submit">Registrar</button>
                </form>
            </section>
        </div>
    );
};

export default Register;
