import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Login = ({ setIsAuthenticated }) => {  // Assuming setIsAuthenticated is passed as a prop
    const [credentials, setCredentials] = useState({ username: '', password: '' });
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    const handleChange = (e) => {
        setCredentials({ ...credentials, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post('/api/token/', credentials)
            .then(response => {
                localStorage.setItem('token', response.data.access);
                localStorage.setItem('refreshToken', response.data.refresh);
                setIsAuthenticated(true); // Update the state
                navigate('/'); // Redirect to the home page after login
            })
            .catch(error => {
                console.error('Error during login:', error);
                setIsAuthenticated(false);  // Handle failed login attempt
                if (error.response && error.response.status === 401) {
                    setErrorMessage('Nombre de usuario o contraseña incorrecta.');
                } else {
                    setErrorMessage('Ocurrió un error durante el inicio de sesión. Inténtalo de nuevo.');
                }
            });
    };

    return (
        <div>
            <section id="login">
                <h2>Login</h2>
                <form onSubmit={handleSubmit}>
                    <label htmlFor="username">Nombre de Usuario:</label>
                    <input type="text" id="username" name="username" onChange={handleChange} required />
                    <label htmlFor="password">Contraseña:</label>
                    <input type="password" id="password" name="password" onChange={handleChange} required />
                    {/* Display error message */}
                    {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                    <p><Link to="/password_reset">Recuperar contraseña</Link>.</p>
                    <button type="submit">Login</button>
                </form>
                <p>¿No tienes una cuenta? <Link to="/register">Regístrate aquí</Link>.</p>
            </section>
        </div>
    );
};

export default Login;
