import React from 'react';
import './FamilyTree.css';

const FamilyTree = () => {
  return (
    <section id="family-tree" className="family-tree-section">
      <h2>Árbol Genealógico</h2>
      <p>Enlace al árbol genealógico.</p>
      <a href="https://www.myheritage.es/FP/entry.php?s=33468721" target="_blank" className="button button3">Árbol Genealógico</a>
    </section>
  );
}

export default FamilyTree;
