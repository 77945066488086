import React, { useState } from 'react';
import axios from 'axios';

const ChangePassword = () => {
  const [formData, setFormData] = useState({
    current_password: '',
    new_password: '',
    confirm_new_password: '',
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);

    axios.post('/api/change-password/', formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(response => {
        setLoading(false);
        setSuccess(response.data.detail);
        setFormData({
          current_password: '',
          new_password: '',
          confirm_new_password: '',
        });
      })
      .catch(error => {
        setLoading(false);
        setError(error.response.data);
      });
  };

  return (
    <div>
        <section id="profile">
            <h2>Change Password</h2>
            <form onSubmit={handleSubmit}>
                <label>Current Password</label>
                <input
                    type="password"
                    name="current_password"
                    value={formData.current_password}
                    onChange={handleChange}
                    required
                />
                <label>New Password</label>
                <input
                    type="password"
                    name="new_password"
                    value={formData.new_password}
                    onChange={handleChange}
                    required
                />
                <label>Confirm New Password</label>
                <input
                    type="password"
                    name="confirm_new_password"
                    value={formData.confirm_new_password}
                    onChange={handleChange}
                    required
                />
                <button type="submit" disabled={loading}>Change Password</button>
            </form>

            {error && (
                <div style={{ color: 'red' }}>
                {Object.keys(error).map(key => (
                    <p key={key}>{error[key][0]}</p>
                ))}
                </div>
            )}

            {success && (
                <div style={{ color: 'green' }}>
                <p>{success}</p>
                </div>
            )}
        </section>
    </div>
  );
};

export default ChangePassword;
