import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import './UserProfile.css';
import axios from 'axios';

const UserProfile = () => {
  const [profile, setProfile] = useState({
    user: {
      first_name: '',
      last_name: '',
      email: '',
    },
    bio: '',
    location: '',
    birth_date: '',
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    axios.get('/api/profile/', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(response => {
        setProfile(response.data);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile(prevProfile => ({
      ...prevProfile,
      [name]: value,
    }));
  };

  const handleUserChange = (e) => {
    const { name, value } = e.target;
    setProfile(prevProfile => ({
      ...prevProfile,
      user: {
        ...prevProfile.user,
        [name]: value,
      },
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Create a copy of the profile data, omitting the username
    const updatedProfile = {
      ...profile,
      user: {
        ...profile.user,
        username: undefined  // Ensure username is not included in the PUT request
      }
    };

    axios.put('/api/profile/', updatedProfile, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(response => {
        console.log('Profile updated successfully:', response.data);
        alert('Profile updated successfully');
      })
      .catch(error => {
        console.error('Error updating profile:', error.response.data);
        setError(error);
      });
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading profile</p>;

  return (
    <div>
      <section id="profile">
        <h2>User Profile</h2>
        <form onSubmit={handleSubmit}>
            <label htmlFor="first_name">First Name</label>
            <input type="text" name="first_name" value={profile.user.first_name}onChange={handleUserChange}/>

            <label>Last Name</label>
            <input
              type="text"
              name="last_name"
              value={profile.user.last_name}
              onChange={handleUserChange}
            />

            <label>Email</label>
            <input
              type="email"
              name="email"
              value={profile.user.email}
              onChange={handleUserChange}
            />
            <label>Bio</label>
            <textarea
              name="bio"
              value={profile.bio}
              onChange={handleChange}
            />

            <label>Location</label>
            <input
              type="text"
              name="location"
              value={profile.location}
              onChange={handleChange}
            />
            <label>Birth Date</label>
            <input
              type="date"
              name="birth_date"
              value={profile.birth_date}
              onChange={handleChange}
            />
          <button type="submit">Update Profile</button>
          <a href="/password-update" >Cambia tu contraseña</a>
        </form>
      </section>
    </div>
  );
};

export default UserProfile;
