import React, { useEffect, useState } from 'react';
import './Information.css'; // Ensure to create and link a corresponding CSS file

const Information = () => {
  const [weatherData, setWeatherData] = useState({ description: '', temperature: '', icon: '' });
  const [error, setError] = useState(null);

  const city = "Villa de Reyes"; 
  const apiKey = "ff80a6fe59decd5e29a9440f228d483a";

  useEffect(() => {
    const fetchWeather = async () => {
      try {
        const response = await fetch(`https://api.openweathermap.org/data/2.5/weather?q=${city}&appid=${apiKey}&units=metric&lang=es`);
        if (!response.ok) throw new Error('Network response was not ok');
        const data = await response.json();
        const weatherDescription = data.weather[0].description;
        const temperature = data.main.temp;
        const weatherIconCode = data.weather[0].icon;

        setWeatherData({
          description: weatherDescription,
          temperature: `${temperature}°C`,
          icon: `https://openweathermap.org/img/wn/${weatherIconCode}@2x.png`,
        });
      } catch (error) {
        console.error('Fetch weather error:', error);
        setError('Unable to fetch weather data');
      }
    };

    fetchWeather();
  }, [city, apiKey]);
  return (
    <section id="info" className="info-section">
      <h2 className="info-title">Información</h2>
      <p className="info-event">Rodrigada 2025</p>

      <div className="info-details">
        <div className="info-item">
          <h3 className="info-heading">Fechas</h3>
          <h4 className="info-subheading">Julio 24 - 27, 2025</h4>
        </div>

        <div className="info-item">
          <h3 className="info-heading">Organizador</h3>
          <h4 className="info-subheading">Marta y Juan</h4>
        </div>

        <div className="info-item">
          <h3 className="info-heading">Ubicación</h3>
          <h4 className="info-subheading">San Luis Potosí, San Luis Potosí</h4>
        </div>

        <div className="info-item">
          <h3 className="info-heading">Hospedaje</h3>
          <a href="https://www.gogorron.com.mx/hotel/" className="button button1">Centro Vacacional Gogorrón</a>
          <a href="tel:524448121550" className="button button1">Teléfono: +524448121550</a>
        </div>

        <div className="map-container">
          <iframe className="map-iframe"
            title="Map"
            src="https://www.google.com/maps/embed/v1/search?q=Centro+Vacacional+Gogorrón&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
            height="450" width="450" style={{ border: 0 }} allowFullScreen loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>

      <div id="weather-container" className="weather-container">
        <h3 className="info-heading">Pronóstico del tiempo</h3>
        {error ? (
          <p>{error}</p>
        ) : (
          <>
            {weatherData.icon && <img src={weatherData.icon} alt="Weather icon" id="weatherIcon" style={{ width: '75px', height: '75px' }} />}
            <p>{`El tiempo en ${city}: ${weatherData.description}, ${weatherData.temperature}`}</p>
          </>
        )}
      </div>
    </section>
  );
}

export default Information;