import React from 'react';
import { NavLink } from 'react-router-dom';
import './Juegos.css'; // You can add styles for this section here

const Juegos = () => {
  return (
    <section id="juegos" className="juegos-section">
      <h2>Juegos</h2>
      <div className="game-links">
        <NavLink to="/juegos/familia-en-juego" className="game-link">La Familia en Juego (Family Feud)</NavLink>
        <NavLink to="/juegos/jeopardia" className="game-link">Trivia</NavLink>
      </div>
    </section>
  );
}
    
export default Juegos;
