import React, { useState, useEffect } from 'react';
import './PastYears.css'; // Assuming you'll add some custom styles here

const PastYears = () => {
  const [selectedYear, setSelectedYear] = useState(2024); // Default to the most recent year
  const [currentImages, setCurrentImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [yearInfo, setYearInfo] = useState(null);

  // Images mapped by year
  const images = {
    2001: ['1.JPG', '2.JPG', '3.JPG', '4.JPG', '5.JPG', '6.JPG', '7.JPG', '8.JPG', '9.JPG', '10.JPG', '11.JPG', '12.JPG', '13.JPG', '14.JPG', '15.JPG','16.JPG', '17.JPG', '18.JPG','19.JPG','20.JPG'],
    2003: ['1.JPG', '2.JPG', '3.JPG', '4.JPG', '5.JPG', '6.JPG', '7.JPG'],
    2004: ['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg', '6.jpg', '7.jpg', '8.jpg', '9.jpg', '10.jpg', '11.jpg', '12.jpg', '13.jpg', '14.jpg', '15.jpg','16.jpg', '17.jpg', '18.jpg','19.jpg','20.jpg', '21.jpg', '22.jpg', '23.jpg', '24.jpg', '25.jpg', '26.jpg','27.jpg', '28.jpg', '29.jpg','30.jpg','31.jpg', '32.jpg', '33.jpg', '34.jpg'],
    2006: ['1.JPG'],
    2007: ['1.JPG', '2.JPG', '3.JPG', '4.JPG', '5.JPG', '6.JPG', '7.JPG', '8.JPG', '9.JPG', '10.JPG', '11.JPG', '12.JPG', '13.JPG', '14.JPG', '15.JPG','16.JPG' ],
    2008: ['1.JPG'],
    2009: ['1.JPG', '2.JPG', '3.JPG', '4.JPG', '5.JPG', '6.JPG', '7.JPG', '8.JPG', '9.JPG', '10.JPG', '11.JPG', '12.JPG', '13.JPG', '14.JPG', '15.JPG','16.JPG', '17.JPG', '18.JPG','19.JPG','20.JPG', '21.JPG', '22.JPG', '23.JPG', '24.JPG', '25.JPG', '26.JPG','27.JPG', '28.JPG', '29.JPG','30.JPG','31.JPG', '32.JPG', '33.JPG', '34.JPG', '35.JPG', '36.JPG','37.JPG', '38.JPG', '39.JPG','40.JPG','41.JPG', '42.JPG', '43.JPG', '44.JPG', '45.JPG', '46.JPG','47.JPG', '48.JPG', '49.JPG','50.JPG','51.JPG', '52.JPG', '53.JPG', '54.JPG', '55.JPG', '56.JPG','57.JPG', '58.JPG', '59.JPG','60.JPG','61.JPG'],
    2010: ['1.JPG', '2.JPG', '3.JPG', '4.JPG', '5.JPG', '6.JPG', '7.JPG', '8.JPG', '9.JPG', '10.JPG', '11.JPG', '12.JPG', '13.JPG', '14.JPG', '15.JPG','16.JPG', '17.JPG', '18.JPG','19.JPG','20.JPG', '21.JPG', '22.JPG', '23.JPG', '24.JPG', '25.JPG', '26.JPG','27.JPG', '28.JPG', '29.JPG','30.JPG','31.JPG', '32.JPG', '33.JPG', '34.JPG', '35.JPG', '36.JPG','37.JPG', '38.JPG', '39.JPG','40.JPG','41.JPG', '42.JPG', '43.JPG', '44.JPG', '45.JPG', '46.JPG','47.JPG', '48.JPG', '49.JPG','50.JPG','51.JPG', '52.JPG', '53.JPG', '54.JPG', '55.JPG', '56.JPG','57.JPG'],
    2011: ['1.JPG'],
    2012: ['1.JPG'],
    2014: ['1.JPG', '2.JPG', '3.JPG', '4.JPG', '5.JPG', '6.JPG', '7.JPG', '8.JPG', '9.JPG'],
    2015: ['1.JPG', '2.JPG', '3.JPG', '4.JPG', '5.JPG', '6.JPG'],
    2016: ['1.JPG', '2.JPG', '3.JPG', '4.JPG', '5.JPG', '6.JPG', '7.JPG'],
    2017: ['1.JPG', '2.JPG', '3.JPG'],
    2018: ['1.JPG'],
    2019: ['1.JPG', '2.JPG'],
    // 2023: ['1.jpeg', '2.jpeg', '3.jpeg', '4.jpeg', '5.jpeg', '6.jpeg', '7.jpeg', '8.jpeg', '9.jpeg', '10.jpeg', '11.jpeg', '12.jpeg', '13.jpeg', '14.jpeg', '15.jpeg','16.jpeg', '17.jpeg', '18.jpeg','19.jpeg','20.jpeg', '21.jpeg', '22.jpeg', '23.jpeg', '24.jpeg', '25.jpeg', '26.jpeg','27.jpeg', '28.jpeg', '29.jpeg','30.jpeg','31.jpeg', '32.jpeg', '33.jpeg', '34.jpeg', '35.jpeg', '36.jpeg','37.jpeg', '38.jpeg', '39.jpeg','40.jpeg','41.jpeg', '42.jpeg', '43.jpeg', '44.jpeg', '45.jpeg', '46.jpeg', '47.jpeg'],
    2024: ['1.jpeg', '2.jpeg', '3.jpeg', '4.jpeg', '5.jpeg', '6.jpeg', '7.jpeg', '8.jpeg', '9.jpeg', '10.jpeg', '11.jpeg', '12.jpeg', '13.jpeg', '14.jpeg', '15.jpeg','16.jpeg', '17.jpeg', '18.jpeg','19.jpeg','20.jpeg', '21.jpeg', '22.jpeg', '23.jpeg', '24.jpeg']
    // Add more years and images as needed
  };

  // Info mapped by year
  const infoByYear = {
    2025: {
      event: 'Rodrigada 2025',
      dates: 'Julio 24 - 27, 2025',
      location: 'San Luis Potosí, San Luis Potosí',
      lodging: 'Hospedaje en San Luis Potosí',
      organizer: 'NA',
  },
  2024: {
      event: 'Rodrigada 2024',
      dates: 'Julio 24 - 27, 2024',
      location: 'Guadalajara',
      lodging: 'Hospedaje en Guadalajara',
      organizer: 'NA',
  },
  2023: {
      event: 'Rodrigada 2023',
      dates: 'Julio 24 - 27, 2023',
      location: 'Morellia, Michoacán',
      lodging: 'NA',
      organizer: 'Juan Torres, Ginsela Rodriguez, Y Sergio Villacana',
  },
  2019: {
      event: 'Rodrigada 2019',
      dates: 'Julio, 2019',
      location: 'Ciudad de México',
      lodging: 'NA',
      organizer: 'Ginsela Rodriguez, Y Sergio Villacana',
  },
  2018: {
      event: 'Rodrigada 2018',
      dates: 'Julio, 2018',
      location: 'Mazamittla, Jalisco',
      lodging: 'NA',
      organizer: 'Dora Sahagun Y Juan Torres',
  },
  2017: {
      event: 'Rodrigada 2017',
      dates: 'Julio, 2017',
      location: 'Ajijic, Jalisco',
      lodging: 'NA',
      organizer: 'Gisela Rodriguez',
  },
  2016: {
      event: 'Rodrigada 2016',
      dates: 'Julio, 2016',
      location: 'Tamazula, Jalisco',
      lodging: '',
      organizer: 'Mario Rodriguez Y Juan Torres',
  },
  2015: {
      event: 'Rodrigada 2015',
      dates: 'Julio, 2015',
      location: 'Gogorron, San Luis Potosí',
      lodging: 'NA',
      organizer: 'Ignacio Rodriguez Y Juan Torres',
  },
  2014: {
      event: 'Rodrigada 2014',
      dates: 'Julio, 2014',
      location: 'Tenacatita, Jalisco',
      lodging: 'NA',
      organizer: 'Ignacio Rodriguez Y Lalo Lupercio',
  },
  2013: {
      event: 'Rodrigada 2013',
      dates: 'Julio, 2013',
      location: 'Uruapan, Michoacán',
      lodging: 'NA',
      organizer: 'Lalo Lupercio Martinez',
  },
  2012: {
      event: 'Rodrigada 2012',
      dates: 'Julio, 2012',
      location: 'Gogorron, San Luis Potosí',
      lodging: 'NA',
      organizer: 'Emerenciano Rodriguez Y Elsa Maria Orozco',
  },
  2011: {
      event: 'Rodrigada 2011',
      dates: 'Julio, 2011',
      location: 'Comanjilla, Guanajuato',
      lodging: 'NA',
      organizer: 'Carlos Ochoa, Pili Bejar, Y Mario Avila',
  },
  2010: {
      event: 'Rodrigada 2010',
      dates: 'Julio, 2010',
      location: 'Tenacatita, Jalisco',
      lodging: 'NA',
      organizer: 'Judith Rodriguez Y Bere Rodriguez',
  },
  2009: {
      event: 'Rodrigada 2009',
      dates: 'Julio, 2009',
      location: 'Tequisquiapan, Querétaro',
      lodging: 'NA',
      organizer: 'Judith Rodriguez Y Luis Rabago',
  },
  2008: {
      event: 'Rodrigada 2008',
      dates: 'Julio, 2008',
      location: 'Gogorron, San Luis Potosí',
      lodging: 'NA',
      organizer: 'Emerenciano Rodriguez Y Elsa Maria Orozco',
  },
  2007: {
      event: 'Rodrigada 2007',
      dates: 'Julio, 2007',
      location: 'Sahuayo, Michoacán',
      lodging: 'NA',
      organizer: 'Dora Sahagun Y Primas',
  },
  2006: {
      event: 'Rodrigada 2006',
      dates: 'Julio, 2006',
      location: 'Tampico, Tamaulipas',
      lodging: 'NA',
      organizer: 'Barron-Lu- Rodriguez Ruben',
  },
  2005: {
      event: 'Rodrigada 2005',
      dates: 'Julio, 2005',
      location: 'Guadalajara, Jalisco',
      lodging: 'NA',
      organizer: 'Familia Gisela Rodgriguez Y Julieta Rodriguez',
  },
  2004: {
      event: 'Rodrigada 2004',
      dates: 'Julio, 2004',
      location: 'Guadalajara, Jalisco',
      lodging: 'NA',
      organizer: 'Judith Garica Y Hijas',
  },
  2003: {
      event: 'Rodrigada 2003',
      dates: 'Julio, 2003',
      location: 'Tlaxcala, Tlaxcala',
      lodging: 'Centro Vacacional La Trinidad',
      organizer: 'Panchin Rodriguez, Alejandra Y Adriana Ovando',
  },
  2002: {
      event: 'Rodrigada 2002',
      dates: 'Julio, 2002',
      location: 'Tlaxcala, Tlaxcala',
      lodging: 'Centro Vacacional La Trinidad',
      organizer: 'Panchin Rodriguez, Alejandra Y Adriana Ovando',
  },
  2001: {
      event: 'Rodrigada 2001',
      dates: 'Julio, 2001',
      location: 'Tlaxcala, Tlaxcala',
      lodging: 'Centro Vacacional La Trinidad',
      organizer: 'Panchin Rodriguez',
  },
    // Add more years and their information as needed
  };

  useEffect(() => {
    setCurrentImages(images[selectedYear] || []);
    setYearInfo(infoByYear[selectedYear] || null);
  }, [selectedYear]);

  const handleYearChange = (e) => {
    setSelectedYear(parseInt(e.target.value, 10));
  };

  const openLightbox = (index) => {
    setCurrentImageIndex(index);
    setIsLightboxOpen(true);
  };

  const closeLightbox = () => {
    setIsLightboxOpen(false);
  };

  const showPrevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + currentImages.length) % currentImages.length);
  };

  const showNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % currentImages.length);
  };

  return (
    <section id="past">
      <h2>Años Pasados</h2>
      <div className="select-container">
        <label htmlFor="year-select">Seleccionar Año:</label>
        <select id="year-select" value={selectedYear} onChange={handleYearChange}>
          {Array.from({ length: 2024 - 2001 + 1 }, (_, i) => 2001 + i).map((year) => (
            <option value={year} key={year}>{year}</option>
          ))}
        </select>
      </div>
      <div className="poll">
      <div id="dynamic-info">
        {yearInfo ? (
          <>
            <h2 className="info-title">Información</h2>
            <p className="info-event">{yearInfo.event}</p>
            <div className="info-details">
              <div className="info-item">
                <h3 className="info-heading">Fechas</h3> 
                <h4 className="info-subheading">{yearInfo.dates}</h4>
              </div>
              <div className="info-item">
                <h3 lassName="info-heading">Organizador </h3>
                <h4 className="info-subheading">{yearInfo.organizer}</h4>
              </div>
              <div className="info-item">
                <h3 className="info-heading">Ubicación</h3>
                <h4 className="info-subheading">{yearInfo.location}</h4>
              </div>
              <div className="info-item">
                <h3 className="info-heading">Hospedaje</h3>
                <h4 className="info-subheading">{yearInfo.lodging}</h4> 
              </div>
            </div>
          </>
        ) : (
          <p>No hay información disponible para este año.</p>
        )}
      </div>
      </div>
      <div id="photo-gallery">
        {currentImages.length > 0 ? (
          currentImages.map((filename, index) => (
            <img
              key={index}
              src={`images/${selectedYear}/${filename}`}
              alt={`${selectedYear} photo`}
              onClick={() => openLightbox(index)}
              onError={(e) => console.error(`Failed to load image: /images/${selectedYear}/${filename}`)}
            />
          ))
        ) : (
          <p>No hay imágenes disponibles para este año.</p>
        )}
      </div>

      {isLightboxOpen && (
        <div id="lightbox" className="lightbox" onClick={closeLightbox}>
          <span className="lightbox-close" onClick={closeLightbox}>&times;</span>
          <img className="lightbox-content" src={`images/${selectedYear}/${currentImages[currentImageIndex]}`} alt="Lightbox" />
          <a className="lightbox-prev" onClick={(e) => { e.stopPropagation(); showPrevImage(); }}>&#10094;</a>
          <a className="lightbox-next" onClick={(e) => { e.stopPropagation(); showNextImage(); }}>&#10095;</a>
        </div>
      )}
    </section>
  );
};

export default PastYears;
