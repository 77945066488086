import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Navigate, Routes, useLocation, useNavigate } from 'react-router-dom';
import Header from './components/Header';
import Announcement from './components/Announcement';
import Information from './components/Information';
import PastYears from './components/PastYears';
import RSVP from './components/RSVP';
import FamilyTree from './components/FamilyTree';
import Footer from './components/Footer';
import Login from './components/Login'; 
import Juegos from './components/Juegos';
import FamiliaEnJuego from './components/FamiliaEnJuego';
import Jeopardia from './components/Jeopardia';
import Poll from './components/Poll';
import PollResults from './components/PollResults';
import Register from './components/Register';
import PasswordReset from './components/PasswordReset';
import PasswordResetConfirm from './components/PasswordResetConfirm';
import UserProfile from './components/UserProfile';
import ChangePassword from './components/ChangePassword';
import axios from 'axios';


function Main({ setIsAuthenticated, isAuthenticated }) {
  const location = useLocation();  
  const navigate = useNavigate();  

  useEffect(() => {
    const token = localStorage.getItem('token');
    console.log('Initial isAuthenticated:', isAuthenticated);
  
    if (token && !isAuthenticated) {
      console.log('Token found, verifying...');
      axios.post('/api/token/verify/', { token: token })
        .then(response => {
          console.log('Token is valid');
          setIsAuthenticated(true);
          localStorage.setItem('isAuthenticated', true);
          const lastPath = localStorage.getItem('lastPath');
          if (lastPath && lastPath !== '/login') { // Ensure we don't navigate to login
            console.log('Navigating to last path:', lastPath);
            navigate(lastPath);
            localStorage.removeItem('lastPath');
          }
        })
        .catch(error => {
          console.error('Token verification error:', error);
          setIsAuthenticated(false);
          localStorage.removeItem('token');
          localStorage.removeItem('isAuthenticated');
        });
    } else if (!token) {
      console.log('No token found');
      setIsAuthenticated(false);
      localStorage.removeItem('isAuthenticated');
    }
  }, [isAuthenticated, navigate, setIsAuthenticated]);
  
  useEffect(() => {
    if (!isAuthenticated && location.pathname !== '/login') {
      console.log('Saving last path:', location.pathname);
      localStorage.setItem('lastPath', location.pathname);
    }
  }, [isAuthenticated, location]);
  
  return (
    <>
      <Header />
      <Routes>
        <Route path="/login" element={<Login setIsAuthenticated={setIsAuthenticated} />} />
        <Route path="/register" element={<Register />} />
        <Route path="/password_reset" element={<PasswordReset />} />
        <Route path="/reset/:uid/:token" element={<PasswordResetConfirm />} />
        <Route
          path="/"
          element={isAuthenticated ? <Announcement /> : <Navigate to="/login" />}
        />
        <Route
          path="/profile"
          element={isAuthenticated ? <UserProfile  /> : <Navigate to="/login" />}
        />
         <Route
          path="/password-update"
          element={isAuthenticated ? <ChangePassword  /> : <Navigate to="/password-update" />}
        />
        <Route
          path="/information"
          element={isAuthenticated ? <Information /> : <Navigate to="/login" />}
        />
        <Route
          path="/past-years"
          element={isAuthenticated ? <PastYears /> : <Navigate to="/login" />}
        />
        <Route
          path="/rsvp"
          element={isAuthenticated ? <RSVP /> : <Navigate to="/login" />}
        />
        <Route
          path="/family-tree"
          element={isAuthenticated ? <FamilyTree /> : <Navigate to="/login" />}
        />
        <Route
          path="/juegos"
          element={isAuthenticated ? <Juegos /> : <Navigate to="/juegos" />}
        />
        <Route
          path="/poll"
          element={isAuthenticated ? <Poll/> : <Navigate to="/poll" />}
        />
        <Route 
          path="/juegos/familia-en-juego" 
          element={isAuthenticated ? <FamiliaEnJuego /> : <Navigate to="/juegos/familia-en-juego" />} />
        <Route 
          path="/juegos/jeopardia" 
          element={isAuthenticated ? <Jeopardia /> : <Navigate to="/juegos/jeopardia" />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <Footer />
    </>
  );
}

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  return (
    <Router>
      <Main setIsAuthenticated={setIsAuthenticated} isAuthenticated={isAuthenticated} />
    </Router>
  );
}

export default App;
