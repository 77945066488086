import React, { useState, useEffect } from 'react';
import axios from 'axios';

const RSVP = () => {
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    group_size: 1,
    email: '',
    comments: '',
  });

  const [attendees, setAttendees] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    // Fetch the list of attendees when the component mounts
    axios.get('/api/attendees/', {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
      .then(response => {
        setAttendees(response.data);
      })
      .catch(error => console.error('Error fetching attendees:', error));
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    // Submit form data via POST request
    axios.post('/api/attendees/', formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
      .then(response => {
        setAttendees([...attendees, response.data]);
        setFormData({ first_name: '', last_name: '', group_size: 1, email: '', comments: '' });
      })
      .catch(error => console.error('Error:', error));
  };

  return (
    <section id="rsvp">
      <h2>Confirmar Asistencia</h2>
      <form id="rsvp-form" onSubmit={handleSubmit}>
        <label htmlFor="first_name">Nombre:</label>
        <input type="text" id="first_name" name="first_name" value={formData.first_name} onChange={handleChange} required />

        <label htmlFor="last_name">Apellido:</label>
        <input type="text" id="last_name" name="last_name" value={formData.last_name} onChange={handleChange} required />

        <label htmlFor="email">Correo Electrónico:</label>
        <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />

        <label htmlFor="group_size">Número de personas en tu grupo:</label>
        <input type="number" id="group_size" name="group_size" value={formData.group_size} onChange={handleChange} min="1" required />

        <label htmlFor="comments">Mensaje (Opcional):</label>
        <textarea id="comments" name="comments" value={formData.comments} onChange={handleChange}></textarea>

        <button type="submit">Enviar</button>
      </form>

      <h3>Lista de Asistentes</h3>
      <table>
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Apellido</th>
            <th>Grupo</th>
            {/* <th>Correo Electrónico</th>
            <th>Comentarios</th> */}
          </tr>
        </thead>
        <tbody>
          {attendees.map((attendee) => (
            <tr key={attendee.id}>
              <td>{attendee.first_name}</td>
              <td>{attendee.last_name}</td>
              <td>{attendee.group_size}</td>
              {/* <td>{attendee.email}</td>
              <td>{attendee.comments}</td> */}
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
}

export default RSVP;
