import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faInfoCircle, faCalendarAlt, faClipboardCheck, faTree, faBars, faGamepad, faSquarePollVertical, faUser } from '@fortawesome/free-solid-svg-icons';
import './Header.css'; 

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="header">
      <img src="images/header-icon.png" alt="Family Reunion Icon" className="header-icon" />
      <nav className={`nav ${menuOpen ? 'open' : ''}`}>
        <ul>
          <li>
            <NavLink to="/" onClick={toggleMenu}>
              <FontAwesomeIcon icon={faHome} /> Home
            </NavLink>
          </li>
          <li>
            <NavLink to="/information" onClick={toggleMenu}>
              <FontAwesomeIcon icon={faInfoCircle} /> Información
            </NavLink>
          </li>
          <li>
            <NavLink to="/past-years" onClick={toggleMenu}>
              <FontAwesomeIcon icon={faCalendarAlt} /> Años Pasados
            </NavLink>
          </li>
          <li>
            <NavLink to="/rsvp" onClick={toggleMenu}>
              <FontAwesomeIcon icon={faClipboardCheck} /> Confirmar Asistencia
            </NavLink>
          </li>
          <li>
            <NavLink to="/family-tree" onClick={toggleMenu}>
              <FontAwesomeIcon icon={faTree} /> Árbol Genealógico
            </NavLink>
          </li>
          <li>
            <NavLink to="/poll" onClick={toggleMenu}>
              <FontAwesomeIcon icon={faSquarePollVertical} /> Encuestas
            </NavLink>
          </li>
          <li>
            <NavLink to="/juegos" onClick={toggleMenu}>
              <FontAwesomeIcon icon={faGamepad} /> Juegos
            </NavLink>
          </li>
          <li>
            <NavLink to="/profile" onClick={toggleMenu}>
              <FontAwesomeIcon icon={faUser} /> Perfil
            </NavLink>
          </li>
        </ul>
      </nav>
      <button className="menu-toggle" onClick={toggleMenu}>
        <FontAwesomeIcon icon={faBars} />
      </button>
    </header>
  );
};

export default Header;
