import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Jeopardia = () => {
  const [categories, setCategories] = useState([]);
  const [score, setScore] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(null);

  useEffect(() => {
    // Fetch Jeopardy categories and questions from the API
    const token = localStorage.getItem('token');
    axios.get('/api/jeopardy-categories/', {
        headers: {
            Authorization: `Bearer ${token}`,
          }
    })
      .then(response => {
        setCategories(response.data);
      })
      .catch(error => console.error('Error fetching Jeopardy categories:', error));
  }, []);

  const selectQuestion = (categoryIndex, questionIndex) => {
    const selectedQuestion = categories[categoryIndex].questions[questionIndex];
    setCurrentQuestion(selectedQuestion);
    // Mark the question as answered (optional, depending on your API setup)
    const updatedCategories = [...categories];
    updatedCategories[categoryIndex].questions[questionIndex].answered = true;
    setCategories(updatedCategories);
  };

  const handleAnswer = (correct) => {
    if (correct) {
      setScore(score + currentQuestion.points);
    }
    setCurrentQuestion(null);
  };

  return (
    <div className="jeopardia">
        <section id="anuncios">
        <h2>Trivia</h2>
        <div className="categories">
            {categories.map((category, categoryIndex) => (
            <div key={categoryIndex} className="category">
                <h3>{category.name}</h3>
                <ul>
                {category.questions.map((q, questionIndex) => (
                    <li key={questionIndex}>
                    {!q.answered ? (
                        <button onClick={() => selectQuestion(categoryIndex, questionIndex)}>
                        {q.points} puntos
                        </button>
                    ) : (
                        <span>Respondido</span>
                    )}
                    </li>
                ))}
                </ul>
            </div>
            ))}
        </div>
        {currentQuestion && (
            <div className="question">
            <h4>{currentQuestion.question_text}</h4>
            <button onClick={() => handleAnswer(true)}>Correcto</button>
            <button onClick={() => handleAnswer(false)}>Incorrecto</button>
            </div>
        )}
        <div className="score">
            <h4>Puntuación: {score}</h4>
        </div>
        </section>
    </div>
  );
}

export default Jeopardia;
