import React, { useState, useEffect } from 'react';
import axios from 'axios';

const FamiliaEnJuego = () => {
  const [questions, setQuestions] = useState([]);
  const [round, setRound] = useState(1);
  const [score, setScore] = useState({ team1: 0, team2: 0 });
  const [currentQuestion, setCurrentQuestion] = useState(null);

  useEffect(() => {
    // Fetch Family Feud questions from the API
    axios.get('/api/family-feud-questions/')
      .then(response => {
        setQuestions(response.data);
        setCurrentQuestion(response.data[0]); // Start with the first question
      })
      .catch(error => console.error('Error fetching Family Feud questions:', error));
  }, []);

  const startRound = () => {
    setCurrentQuestion(questions[round - 1]);
  };

  const addPoints = (team, points) => {
    setScore((prevScore) => ({
      ...prevScore,
      [team]: prevScore[team] + points
    }));
  };

  return (
    <div className="familia-en-juego">
      <h2>La Familia en Juego</h2>
      {currentQuestion ? (
        <div>
          <h3>{currentQuestion.question_text}</h3>
          <ul>
            {currentQuestion.answers.map((answer, index) => (
              <li key={index}>
                {answer.answer_text} - {answer.points} puntos
                <button onClick={() => addPoints('team1', answer.points)}>Equipo 1</button>
                <button onClick={() => addPoints('team2', answer.points)}>Equipo 2</button>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <button onClick={startRound}>Comenzar Ronda {round}</button>
      )}
      <div className="score">
        <h4>Puntuación</h4>
        <p>Equipo 1: {score.team1}</p>
        <p>Equipo 2: {score.team2}</p>
      </div>
    </div>
  );
}

export default FamiliaEnJuego;
