import React, { useState, useEffect } from 'react';
import './Poll.css';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const PollsList = () => {
  const [polls, setPolls] = useState([]);
  const [submittedPolls, setSubmittedPolls] = useState(new Set()); // Keep track of submitted polls
  const [results, setResults] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState({}); // Store selected options for each poll
  const [freeResponses, setFreeResponses] = useState({}); // Store free responses for each poll
  const token = localStorage.getItem('token');

  useEffect(() => {
    // Fetch all available polls
    axios.get('/api/polls/?past=False', {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
      .then(response => {
        setPolls(response.data);
      })
      .catch(error => console.error('Error fetching polls:', error));

    // Fetch results for past polls
    axios.get('/api/polls/?past=True', {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
      .then(response => {
        const fetchedPolls = response.data;

        // Fetch responses for each poll
        const responsePromises = fetchedPolls.map(poll =>
          axios.get(`/api/poll-responses/?poll=${poll.id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            }
          })
        );

        Promise.all(responsePromises)
          .then(responseData => {
            const pollsWithResponses = fetchedPolls.map((poll, index) => ({
              ...poll,
              responses: responseData[index].data, // Attach the responses to the poll
            }));
            setResults(pollsWithResponses);
          })
          .catch(error => console.error('Error fetching poll responses:', error));
      })
      .catch(error => console.error('Error fetching poll results:', error));
  }, [token]);

  const handleOptionChange = (pollId, optionId) => {
    setSelectedOptions(prevState => ({
      ...prevState,
      [pollId]: optionId,
    }));
  };

  const handleFreeResponseChange = (pollId, response) => {
    setFreeResponses(prevState => ({
      ...prevState,
      [pollId]: response,
    }));
  };

  const handleSubmit = (pollId) => {
    const response = {
      poll: pollId,
      selected_option: selectedOptions[pollId] || null,
      free_response: freeResponses[pollId] || '',
    };

    axios.post('/api/poll-responses/', response, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
      .then(() => {
        setSubmittedPolls(prev => new Set(prev).add(pollId)); // Mark this poll as submitted
      })
      .catch(error => console.error('Error submitting response:', error));
  };

  return (
    <div className="polls-list">
      <section id="polls">
        <h2>Encuestas Disponibles</h2>
        {polls.map(poll => (
          <div key={poll.id} className="poll">
            <h3>{poll.question_text}</h3>
            {submittedPolls.has(poll.id) ? (
              <p>¡Gracias por tu respuesta!</p>
            ) : (
              <div>
                {poll.is_multiple_choice ? (
                  poll.options.map(option => (
                    <div key={option.id}>
                      <input
                        type="radio"
                        name={`option-${poll.id}`}
                        value={option.id}
                        onChange={() => handleOptionChange(poll.id, option.id)}
                        checked={selectedOptions[poll.id] === option.id}
                      />
                      {option.option_text}
                    </div>
                  ))
                ) : (
                  <textarea
                    value={freeResponses[poll.id] || ''}
                    onChange={e => handleFreeResponseChange(poll.id, e.target.value)}
                    placeholder="Your response"
                  />
                )}
                <button onClick={() => handleSubmit(poll.id)}>Someter</button>
              </div>
            )}
          </div>
        ))}
      </section>

      <section id="polls-result">
        <h2>Resultados de Encuestas Pasadas</h2>
        {results.map(result => (
          <div key={result.id} className="poll-result">
            <h3>{result.question_text}</h3>
            {result.is_multiple_choice ? (
              <Bar
                data={{
                  labels: result.options.map(option => option.option_text),
                  datasets: [
                    {
                      label: 'Votos',
                      data: result.options.map(option =>
                        result.responses ? result.responses.filter(r => r.selected_option === option.id).length : 0
                      ),
                      backgroundColor: 'rgba(75,192,192,1)',
                    },
                  ],
                }}
                options={{
                  scales: {
                    y: {
                      beginAtZero: true,
                    },
                  },
                }}
              />
            ) : (
              <div className="free-response-results">
                {result.responses && result.responses.length > 0 ? (
                  result.responses
                    .filter(response => response.free_response && response.free_response.trim() !== '') // Filter out empty or whitespace-only responses
                    .map((response, index) => (
                      <p key={index} className="free-response">
                        {response.free_response}
                      </p>
                    ))
                ) : (
                  <p>No responses yet.</p>
                )}
              </div>
            )}
          </div>
        ))}
      </section>
    </div>
  );
};

export default PollsList;
